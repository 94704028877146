<template>
    <h1><slot /></h1>
</template>

<style lang="scss" scoped>
    h1 {
        font-size: var(--joy-font-size-primary-800);
        margin-bottom: var(--joy-core-spacing-8);
    }
</style>
